'use client';

import Link from 'next/link';
import AppIcon from '@/components/icons/app-icon';
import DownloadAppIcon from '@/components/icons/download-app-icon';
import { cn } from '@/lib/utils/cn';
import { useParams, usePathname, useSearchParams } from 'next/navigation';

export function Banner() {
  const params = useParams();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const hide = shoulHideBanner(pathname, params, searchParams);

  return (
    <div
      className={cn(
        'sm:hidden h-20 border-b bg-background w-full transition-all origin-top',
        hide && 'hidden'
      )}
    >
      <div className='container h-full flex items-center justify-center w-full'>
        <div className='flex items-center gap-x-6'>
          <AppIcon className='hidden sm:block w-14 h-14' />
          <div className='hidden sm:block'>
            <h3 className='font-bold text-lg'>See and Apply to More Jobs</h3>
            <p className=''>Get the full experience in the app</p>
          </div>
          <Link
            href='https://apps.apple.com/us/app/jobreel/id6473167014'
            target='_blank'
          >
            <DownloadAppIcon className='w-full max-w-[150px]' />
          </Link>
        </div>
      </div>
    </div>
  );
}

function shoulHideBanner(
  pathname: string,
  params: { [key: string]: string | string[] },
  searchParams: URLSearchParams
) {
  return (
    pathname.startsWith('/onboarding/candidate/profile') ||
    pathname.startsWith('/onboarding/candidate/review') ||
    pathname.startsWith('/onboarding/candidate/go-public') ||
    pathname.startsWith('/onboarding/candidate/jobreel') ||
    (pathname.startsWith('/jobs') && !!params['jobHash']) ||
    pathname.startsWith('/app/welcome') ||
    pathname.startsWith('/view/profiles') ||
    (pathname.startsWith('/onboarding/business') &&
      searchParams.get('finished') === 'true') ||
    (pathname.startsWith('/app/my-jobs') &&
      !!params['jobHash'] &&
      !!params['applicationHash']) ||
    (pathname.startsWith('/b') &&
      !!params['business_slug'] &&
      !!params['job_slug'])
  );
}

export default Banner;
