'use client';

import { usePlayerStore } from '@/lib/client/store/player.store';
import { ReactNode, useEffect } from 'react';

export function PlayerProvider({ children }: { children: ReactNode }) {
  const { muted, setMuted } = usePlayerStore();

  useEffect(() => {
    const soundOn = sessionStorage.getItem('jr_sound_on');
    if (soundOn === 'true' && muted) {
      setMuted(false);
    }
    if (soundOn === 'false' && !muted) {
      setMuted(true);
    }
  }, []);

  return children;
}
