'use client';

import { useEffect } from 'react';
import { initializeDatadog } from '@/lib/client/utils/datadog';

export default function DataDogProvider() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      initializeDatadog();
    }
  }, []);

  // Render nothing - this component is only included so that the init code
  // will run client-side
  return null;
}
