// import Business from "@/chore/models/Business.model"
import jobreelApi from '@/lib/utils/jobreel-api';
import {
  getBearerToken,
  getCurrentBusiness,
} from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';
import { env } from '@/lib/env';
import {
  CreateBusinessDto,
  UpdateBusinessDto,
} from '@/modules/business/business.dto';
import { ProfileInterest } from '@/modules/profile/profile.model';
import { Business } from '@/modules/business/business.model';

export const Business_GetCategories = async () => {
  const { data } = await jobreelApi.get<ServerResponse<ProfileInterest[]>>(
    env.baseApiUrl + '/v1/businesses/types',
    {
      headers: { Authorization: getBearerToken() },
    }
  );
  return data.data;
};

export const Business_Create = async (
  payload: CreateBusinessDto
): Promise<Business> => {
  const { data } = await jobreelApi.post<ServerResponse<Business>>(
    '/v1/businesses',
    payload,
    { headers: { Authorization: getBearerToken() } }
  );
  return data.data;
};

export const Business_Update = async (
  businessHash: string,
  payload: UpdateBusinessDto
): Promise<Business> => {
  const { data } = await jobreelApi.patch<ServerResponse<Business>>(
    '/v1/businesses/' + businessHash,
    payload,
    { headers: { Authorization: getBearerToken() } }
  );
  return data.data;
};

export const Business_UpdateCurrent = async (payload: UpdateBusinessDto) => {
  const { data } = await jobreelApi.patch<ServerResponse<Business>>(
    '/v1/businesses/' + getCurrentBusiness(),
    payload,
    { headers: { Authorization: getBearerToken() } }
  );
  return data.data;
};

export const Business_Current = async (): Promise<Business | undefined> => {
  const hash = getCurrentBusiness();
  if (!hash) return;
  return await Business_Retrieve(hash);
};

export const Business_Retrieve = async (hash: string): Promise<Business> => {
  const { data } = await jobreelApi.get<ServerResponse<Business>>(
    '/v1/businesses/' + hash,
    { headers: { Authorization: getBearerToken() } }
  );
  return data.data;
};

export const Business_FindBySlug = async (slug: string) => {
  const { data } = await jobreelApi.get<ServerResponse<Business>>(
    '/v1/businesses/findBySlug',
    {
      params: {
        slug,
      },
    }
  );

  return data.data;
};

export const Business_ListForUser = async () => {
  const { data } = await jobreelApi.get<ServerResponse<Business[]>>(
    `/v1/businesses/list`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};

export const Business_GetLink = async (businessHash: string) => {
  const { data } = await jobreelApi.get<
    ServerResponse<{ qrCode: string; url: string }>
  >(`/v1/businesses/${businessHash}/link`, {
    headers: {
      Authorization: getBearerToken(),
    },
  });

  return data.data;
};
